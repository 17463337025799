import { useEffect } from "react";

import { useCookieConsent } from "@finanzchef24gmbh/cookie-consent/src/hook";
import { NewsletterRegistration_ContentfulNewsletterRegistrationFragment } from "../../types/graphql-types";

declare global {
  interface Window {
    hbspt: any;
  }
}

type HubspotFormProps = {
  contentfulId: NewsletterRegistration_ContentfulNewsletterRegistrationFragment["contentful_id"];
  formId: NewsletterRegistration_ContentfulNewsletterRegistrationFragment["formId"];
};

const useHubspotForm = ({ contentfulId, formId }: HubspotFormProps) => {
  const consentServiceName = "HubSpot Forms";
  const cookieConsent = useCookieConsent({
    shouldRerenderOnConsentChange: true,
  });

  useEffect(() => {
    if (cookieConsent.services?.[consentServiceName]) {
      const hubspotScript = document.createElement("script");
      hubspotScript.src = "https://js-eu1.hsforms.net/forms/v2.js";

      hubspotScript.onload = () => {
        window.hbspt.forms.create({
          formId,
          portalId: "24877130",
          region: "eu1",
          target: `.hbspt-form-${contentfulId}`,
        });
      };

      document.head.appendChild(hubspotScript);

      return () => {
        document.head.removeChild(hubspotScript);
      };
    }
  }, [cookieConsent.services?.[consentServiceName]]);
};

export default useHubspotForm;
